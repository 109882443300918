jQuery(document).ready(function($){

    var body = $('body');

    // DG tab behavior
    body.on('click', '.dg-tab', function(){
        var tabGroup = $(this).parent().attr('data-tab-group');
        $('.dg-tabs[data-tab-group="'+tabGroup+'"] .dg-tab').each(function(){
            $(this).removeClass('active');
            $('#'+$(this).attr('data-tab-id')).removeClass('active');
        });
        $(this).addClass('active');
        $('#'+$(this).attr('data-tab-id')).addClass('active');
    });

    $('.fancybox').fancybox({type: "image"});

});